var sensorList = {};

sensorList.COD = {
  name: 'Cape Cod AFS, Massachusetts',
  shortName: 'COD',
  type: 'Phased Array Radar',
  lat: 41.754785,
  lon: -70.539151,
  alt: 0.060966,
  obsminaz: 347,
  obsmaxaz: 227,
  obsminel: 3,
  obsmaxel: 85,
  obsminrange: 200,
  obsmaxrange: 5556,
  changeObjectInterval: 1000,
  beamwidth: 2.0, // National Research Council 1979. Radiation Intensity of the PAVE PAWS Radar System. Washington, DC: The National Academies Press.
  linkAehf: true,
  linkWgs: true,
  zoom: 'leo',
  url: 'http://www.radartutorial.eu/19.kartei/01.oth/karte004.en.html',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};
sensorList.BLE = {
  name: 'Beale AFB, California',
  shortName: 'BLE',
  type: 'Phased Array Radar',
  lat: 39.136064,
  lon: -121.351237,
  alt: 0.112, // Open Street Maps
  obsminaz: 126,
  obsmaxaz: 6,
  obsminel: 3,
  obsmaxel: 85,
  obsminrange: 200,
  obsmaxrange: 5556,
  changeObjectInterval: 1000,
  beamwidth: 2.0, // National Research Council 1979. Radiation Intensity of the PAVE PAWS Radar System. Washington, DC: The National Academies Press.
  linkAehf: true,
  linkWgs: true,
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};
sensorList.CLR = {
  name: 'Clear AFS, Alaska',
  shortName: 'CLR',
  type: 'Phased Array Radar',
  lat: 64.290556,
  lon: -149.186944,
  alt: 0.175, // Open Street Maps
  obsminaz: 184,
  obsmaxaz: 64,
  obsminel: 3,
  obsmaxel: 85,
  obsminrange: 200,
  obsmaxrange: 5556,
  changeObjectInterval: 1000,
  beamwidth: 2.0, // National Research Council 1979. Radiation Intensity of the PAVE PAWS Radar System. Washington, DC: The National Academies Press.
  linkAehf: true,
  linkWgs: true,
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};
sensorList.EGL = {
  name: 'Eglin AFB, Florida',
  shortName: 'EGL',
  type: 'Phased Array Radar',
  lat: 30.572411,
  lon: -86.214836,
  alt: 0.039, // Open Street Maps
  obsminaz: 120,
  obsmaxaz: 240,
  obsminel: 3,
  obsmaxel: 105,
  obsminrange: 200,
  obsmaxrange: 200000,
  changeObjectInterval: 1000,
  beamwidth: 1.4, // National Research Council 1979. Radiation Intensity of the PAVE PAWS Radar System. Washington, DC: The National Academies Press.
  url: 'http://www.radartutorial.eu/19.kartei/01.oth/karte002.en.html',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};
sensorList.FYL = {
  name: 'RAF Fylingdales, United Kingdom',
  shortName: 'FYL',
  type: 'Phased Array Radar',
  lat: 54.361758,
  lon: -0.670051,
  alt: 0.26, // Open Street Maps
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 3,
  obsmaxel: 85,
  obsminrange: 200,
  obsmaxrange: 5556,
  changeObjectInterval: 1000,
  beamwidth: 2.0, // National Research Council 1979. Radiation Intensity of the PAVE PAWS Radar System. Washington, DC: The National Academies Press.
  linkAehf: true,
  linkWgs: true,
  country: 'United Kingdom',
  sun: 'No Impact',
  volume: false,
};
sensorList.CAV = {
  name: 'Cavalier AFS, North Dakota',
  shortName: 'CAV',
  type: 'Phased Array Radar',
  lat: 48.724567,
  lon: -97.899755,
  alt: 0.352, // Open Street Maps
  obsminaz: 298,
  obsmaxaz: 78,
  obsminel: 1.9,
  obsmaxel: 95,
  obsminrange: 200,
  obsmaxrange: 3300, // 1,780 Nm http://www.fortwiki.com/Cavalier_Air_Force_Station
  changeObjectInterval: 1000,
  beamwidth: 1.2, // National Research Council 1979. Radiation Intensity of the PAVE PAWS Radar System. Washington, DC: The National Academies Press.
  linkAehf: true,
  url: 'https://mostlymissiledefense.com/2012/04/12/parcs-cavalier-radar-april-12-2012/',
  country: 'United States',
  sun: 'No Impact',
  volume: true,
};
sensorList.THL = {
  name: 'Thule AFB, Greenland',
  shortName: 'THL',
  type: 'Phased Array Radar',
  lat: 76.570322,
  lon: -68.299211,
  alt: 0.392, // Open Street Maps
  obsminaz: 297,
  obsmaxaz: 177,
  obsminel: 3,
  obsmaxel: 85,
  obsminrange: 200,
  obsmaxrange: 5556,
  changeObjectInterval: 1000,
  beamwidth: 2.0, // National Research Council 1979. Radiation Intensity of the PAVE PAWS Radar System. Washington, DC: The National Academies Press.
  linkAehf: true,
  linkWgs: true,
  url: 'http://www.radartutorial.eu/19.kartei/01.oth/karte004.en.html',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};
sensorList.CDN = {
  name: 'Cobra Dane, Alaska',
  shortName: 'CDN',
  type: 'Phased Array Radar',
  lat: 52.737,
  lon: 174.092,
  alt: 0.066, // Open Street Maps
  obsminaz: 259,
  obsmaxaz: 19,
  obsminel: 2,
  obsmaxel: 30,
  obsminrange: 200,
  obsmaxrange: 4200,
  obsminaz2: 251,
  obsmaxaz2: 27,
  obsminel2: 30,
  obsmaxel2: 80,
  obsminrange2: 200,
  obsmaxrange2: 4200,
  changeObjectInterval: 1000,
  linkWgs: true,
  url: 'http://www.radartutorial.eu/19.kartei/01.oth/karte004.en.html',
  country: 'United States',
  sun: 'No Impact',
  volume: true,
};
sensorList.ALT = {
  name: 'ALTAIR, Kwajalein Atoll',
  shortName: 'ALT',
  type: 'Mechanical',
  lat: 8.716667,
  lon: 167.733333,
  alt: 0,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 1,
  obsmaxel: 90,
  obsminrange: 200,
  obsmaxrange: 200000,
  changeObjectInterval: 20000,
  linkAehf: true,
  linkWgs: true,
  url: 'http://www.radartutorial.eu/19.kartei/01.oth/karte005.en.html',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};
sensorList.MMW = {
  name: 'Millimeter Wave Radar, Kwajalein Atoll',
  shortName: 'MMW',
  type: 'Mechanical',
  lat: 8.756668,
  lon: 167.773334,
  alt: 0,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 1,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 2500,
  changeObjectInterval: 20000,
  linkAehf: false,
  linkWgs: false,
  url: '',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};
sensorList.ALC = {
  name: 'ALCOR Radar, Kwajalein Atoll',
  shortName: 'ALC',
  type: 'Mechanical',
  lat: 8.716668,
  lon: 167.773334,
  alt: 0,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 1,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 2300,
  changeObjectInterval: 20000,
  linkAehf: false,
  linkWgs: false,
  url: '',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};
sensorList.TDX = {
  name: 'TRADEX Radar, Kwajalein Atoll',
  shortName: 'TDX',
  type: 'Mechanical',
  lat: 8.756668,
  lon: 167.733334,
  alt: 0,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 1,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 200000,
  changeObjectInterval: 20000,
  linkAehf: false,
  linkWgs: false,
  url: '',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};
sensorList.MIL = {
  name: 'Millstone, Massachusetts',
  shortName: 'MIL',
  type: 'Mechanical',
  lat: 42.6233,
  lon: -71.4882,
  alt: 0.131,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 1,
  obsmaxel: 90,
  obsminrange: 200,
  obsmaxrange: 200000,
  changeObjectInterval: 20000,
  url: 'https://mostlymissiledefense.com/2012/05/05/space-surveillance-sensors-millstone-hill-radar/',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};
sensorList.DGC = {
  name: 'Diego Garcia',
  shortName: 'DGC',
  type: 'Optical',
  lat: -7.29648,
  lon: 72.390153,
  alt: 0.0,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 20,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 200000,
  changeObjectInterval: 20000,
  url: 'https://mostlymissiledefense.com/2012/08/20/space-surveillance-sensors-geodss-ground-based-electro-optical-deep-space-surveillance-system-august-20-2012/',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};
sensorList.MAU = {
  name: 'Maui, Hawaii',
  shortName: 'MAU',
  type: 'Optical',
  lat: 20.70835,
  lon: -156.257595,
  alt: 3.0,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 20,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 200000,
  changeObjectInterval: 20000,
  url: 'https://mostlymissiledefense.com/2012/08/20/space-surveillance-sensors-geodss-ground-based-electro-optical-deep-space-surveillance-system-august-20-2012/',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};
sensorList.SOC = {
  name: 'Socorro, New Mexico',
  shortName: 'SOC',
  type: 'Optical',
  lat: 33.817233,
  lon: -106.659961,
  alt: 1.24,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 20,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 200000,
  changeObjectInterval: 20000,
  url: 'https://mostlymissiledefense.com/2012/08/20/space-surveillance-sensors-geodss-ground-based-electro-optical-deep-space-surveillance-system-august-20-2012/',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};
sensorList.ASC = {
  name: 'Ascension Island, United Kingdom',
  shortName: 'ASC',
  type: 'Mechanical',
  lat: -7.969444,
  lon: -14.393889,
  alt: 0.0,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 1,
  obsmaxel: 90,
  obsminrange: 200,
  obsmaxrange: 200000,
  changeObjectInterval: 20000,
  url: '',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};
sensorList.GLB = {
  name: 'Globus II, NOR',
  shortName: 'GLB',
  type: 'Mechanical',
  lat: 70.3671,
  lon: 31.1271,
  alt: 0.0,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 1,
  obsmaxel: 90,
  obsminrange: 200,
  obsmaxrange: 200000,
  changeObjectInterval: 20000,
  url: '',
  country: 'Norway',
  sun: 'No Impact',
  volume: false,
};
sensorList.HOL = {
  name: 'C-Band (Holt) Radar, Australia',
  shortName: 'HOL',
  type: 'Mechanical',
  lat: -21.816195,
  lon: 114.165637,
  alt: 0.0,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 1,
  obsmaxel: 90,
  obsminrange: 200,
  obsmaxrange: 200000,
  changeObjectInterval: 20000,
  url: '',
  country: 'Australia',
  sun: 'No Impact',
  volume: false,
};

// //////////////
// TPY-2 RADARS
// //////////////

sensorList.HAR = {
  name: 'Har Keren TPY-2, Israel',
  shortName: 'HAR',
  type: 'Phased Array Radar',
  lat: 30.995807,
  lon: 34.496062,
  alt: 0.173,
  obsminaz: 5,
  obsmaxaz: 125,
  obsminel: 5,
  obsmaxel: 95,
  obsminrange: 0,
  obsmaxrange: 2000,
  changeObjectInterval: 1000,
  url: '',
  country: 'Israel',
  sun: 'No Impact',
  volume: false,
};
sensorList.QTR = {
  name: 'Centcom TPY-2, Qatar',
  shortName: 'QTR',
  type: 'Phased Array Radar',
  lat: 25.31598,
  lon: 51.146515,
  alt: 0.01,
  obsminaz: 335,
  obsmaxaz: 95,
  obsminel: 0,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 2000,
  changeObjectInterval: 1000,
  url: '',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};
sensorList.KUR = {
  name: 'Kürecik Radar Station, Turkey',
  shortName: 'KUR',
  type: 'Phased Array Radar',
  lat: 38.349444,
  lon: 37.793611,
  alt: 1.969,
  obsminaz: 40,
  obsmaxaz: 160,
  obsminel: 0,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 2000,
  changeObjectInterval: 1000,
  url: '',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};

sensorList.SHA = {
  name: 'Shariki Communication Site, Japan',
  shortName: 'SHA',
  type: 'Phased Array Radar',
  lat: 40.88809,
  lon: 140.337698,
  alt: 0.01,
  obsminaz: 230,
  obsmaxaz: 350,
  obsminel: 0,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 2000,
  changeObjectInterval: 1000,
  url: '',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};

sensorList.KCS = {
  name: 'Kyogamisaki Communication Site, Japan',
  shortName: 'KCS',
  type: 'Phased Array Radar',
  lat: 35.766667,
  lon: 135.195278,
  alt: 0.01,
  obsminaz: 210,
  obsmaxaz: 330,
  obsminel: 0,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 2000,
  changeObjectInterval: 1000,
  url: '',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};

sensorList.SBX = {
  name: 'Sea-Based X-Band Radar, Pacific Ocean',
  shortName: 'SBX',
  type: 'Phased Array Radar',
  lat: 36.5012,
  lon: 169.6941,
  alt: 0.0,
  obsminaz: 275,
  obsmaxaz: 300,
  obsminel: 0,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 4025,
  changeObjectInterval: 1000,
  url: '',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};

// //////////////////////
// LEO LABS
// //////////////////////

sensorList.MSR = {
  name: 'Midland Space Radar, Texas',
  shortName: 'MSR',
  type: 'Phased Array Radar',
  lat: 31.9643,
  lon: -103.233245,
  alt: 0.855,
  obsminaz: 70,
  obsmaxaz: 72,
  obsminel: 30,
  obsmaxel: 91, // 91 to ensure visual overlap
  obsminrange: 100,
  obsmaxrange: 1800,
  obsminaz2: 250,
  obsmaxaz2: 252,
  obsminel2: 30,
  obsmaxel2: 91, // 91 to ensure visual overlap
  obsminrange2: 100,
  obsmaxrange2: 1800,
  changeObjectInterval: 1000,
  url: 'https://platform.leolabs.space/sites/msr',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};

sensorList.PFISR = {
  name: 'Poker Flat Incoherent Scatter Radar, Alaska',
  shortName: 'PFISR',
  type: 'Phased Array Radar',
  lat: 65.130029,
  lon: -147.470992,
  alt: 0.23,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 45,
  obsmaxel: 90, // 91 to ensure visual overlap
  obsminrange: 100,
  obsmaxrange: 1800,
  changeObjectInterval: 1000,
  url: 'https://platform.leolabs.space/sites/pfisr',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};

sensorList.KSR = {
  name: 'Kiwi Space Radar, New Zealand',
  shortName: 'KSR',
  type: 'Phased Array Radar',
  lat: -45.038725,
  lon: 170.095673,
  alt: 0.0,
  obsminaz: 269,
  obsmaxaz: 271,
  obsminel: 20,
  obsmaxel: 91,
  obsminrange: 100,
  obsmaxrange: 1800,
  obsminaz2: 89,
  obsmaxaz2: 91,
  obsminel2: 20,
  obsmaxel2: 91,
  obsminrange2: 100,
  obsmaxrange2: 1800,
  changeObjectInterval: 1000,
  url: 'https://platform.leolabs.space/sites/ksr',
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};

// //////////////////////
// ESOC RADARS
// //////////////////////
sensorList.GRV = {
  name: 'Grand Réseau Adapté à la Veille Spatiale, France',
  shortName: 'GRV',
  type: 'Phased Array',
  lat: 47.347778,
  lon: 5.51638,
  alt: 0.0,
  obsminaz: 90,
  obsmaxaz: 270,
  obsminel: 20,
  obsmaxel: 40,
  obsminrange: 0,
  obsmaxrange: 1700, // http://emits.sso.esa.int/emits-doc/AO5059RD1.pdf
  changeObjectInterval: 20000,
  url: '',
  country: 'France',
  sun: 'No Impact',
  volume: true,
};
sensorList.TIR = {
  name: 'Tracking and Imaging Radar, Germany',
  shortName: 'TIR',
  type: 'Mechanical',
  lat: 50.6166,
  lon: 7.1296,
  alt: 0.0,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 1.5, // http://www.issfd.org/ISSFD_2012/ISSFD23_CRSD2_3.pdf
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 200000, // http://emits.sso.esa.int/emits-doc/AO5059RD1.pdf
  changeObjectInterval: 20000,
  url: '',
  country: 'Germany',
  sun: 'No Impact',
  volume: false,
};
sensorList.NRC = {
  name: 'Croce del Nord, Italy',
  shortName: 'NRC',
  type: 'Bistatic Radio Telescope',
  lat: 44.5208,
  lon: 11.6469,
  alt: 0.025,
  obsminaz: 89.1,
  obsmaxaz: 90.9,
  obsminel: 45,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 1700,
  obsminaz2: 179.1,
  obsmaxaz2: 180.9,
  obsminel2: 45,
  obsmaxel2: 90,
  obsminrange2: 0,
  obsmaxrange2: 1700,
  changeObjectInterval: 20000,
  url: '',
  country: 'Italy',
  sun: 'No Impact',
  volume: false,
};
sensorList.TRO = {
  name: 'RAF Troodos, United Kingdom',
  shortName: 'TRO',
  type: 'Optical',
  lat: 34.912778,
  lon: 32.883889,
  alt: 0,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 10,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 200000,
  changeObjectInterval: 20000,
  url: '',
  country: 'United Kingdom',
  sun: 'No Impact',
  volume: false,
};
sensorList.SDT = {
  name: 'ESA Space Debris Telescope, Spain',
  shortName: 'SDT',
  type: 'Optical',
  lat: 28.3,
  lon: -16.5097,
  alt: 0,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 10,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 200000,
  changeObjectInterval: 20000,
  url: '',
  country: 'Spain',
  sun: 'No Impact',
  volume: false,
};

// //////////////////////
// RUSSIAN RADARS
// //////////////////////
sensorList.ARM = {
  name: 'Armavir, Russia',
  shortName: 'ARM',
  type: 'Phased Array Radar',
  lat: 44.925106,
  lon: 40.983894,
  alt: 0.0,
  obsminaz: 55, // All Information via russianforces.org
  obsmaxaz: 295,
  obsminel: 2,
  obsmaxel: 60,
  obsminrange: 100,
  obsmaxrange: 4200,
  changeObjectInterval: 1000,
  country: 'Russia',
  sun: 'No Impact',
  volume: false,
};
sensorList.BAL = {
  name: 'Balkhash, Russia',
  shortName: 'BAL',
  type: 'Phased Array Radar',
  lat: 46.603076,
  lon: 74.530985,
  alt: 0.0,
  obsminaz: 91, // All Information via russianforces.org
  obsmaxaz: 151,
  obsminel: 5.5,
  obsmaxel: 34.5,
  obsminrange: 385,
  obsmaxrange: 4600,
  changeObjectInterval: 1000,
  country: 'Russia',
  sun: 'No Impact',
  volume: false,
};
sensorList.GAN = {
  name: 'Gantsevichi, Russia',
  shortName: 'GAN',
  type: 'Phased Array Radar',
  lat: 52.85,
  lon: 26.48,
  alt: 0.0,
  obsminaz: 190, // All Information via russianforces.org
  obsmaxaz: 310,
  obsminel: 3,
  obsmaxel: 80,
  obsminrange: 300,
  obsmaxrange: 6500,
  changeObjectInterval: 1000,
  country: 'Russia',
  sun: 'No Impact',
  volume: false,
};
sensorList.LEK = {
  name: 'Lekhtusi, Russia',
  shortName: 'LEK',
  type: 'Phased Array Radar',
  lat: 60.275458,
  lon: 30.546017,
  alt: 0.0,
  obsminaz: 245,
  obsmaxaz: 355,
  obsminel: 2,
  obsmaxel: 70,
  obsminrange: 100,
  obsmaxrange: 4200,
  changeObjectInterval: 1000,
  country: 'Russia',
  sun: 'No Impact',
  volume: false,
};
sensorList.MIS = {
  name: 'Mishelevka-D, Russia',
  shortName: 'MIS',
  type: 'Phased Array Radar',
  lat: 52.8555,
  lon: 103.2317,
  alt: 0.0,
  obsminaz: 41, // All Information via russianforces.org
  obsmaxaz: 219,
  obsminel: 5.5,
  obsmaxel: 34.5,
  obsminrange: 250,
  obsmaxrange: 4600,
  changeObjectInterval: 1000,
  country: 'Russia',
  sun: 'No Impact',
  volume: false,
};
sensorList.OLE = {
  name: 'Olenegorsk, Russia',
  shortName: 'OLE',
  type: 'Phased Array Radar',
  lat: 68.1141,
  lon: 33.9102,
  alt: 0.0,
  obsminaz: 280, // All Information via russianforces.org
  obsmaxaz: 340,
  obsminel: 5.5,
  obsmaxel: 34.5,
  obsminrange: 250,
  obsmaxrange: 4600,
  changeObjectInterval: 1000,
  country: 'Russia',
  sun: 'No Impact',
  volume: false,
};
sensorList.PEC = {
  name: 'Pechora, Russia',
  shortName: 'PEC',
  type: 'Phased Array Radar',
  lat: 65.21,
  lon: 57.295,
  alt: 0.0,
  obsminaz: 305, // All Information via russianforces.org
  obsmaxaz: 55,
  obsminel: 2,
  obsmaxel: 55,
  obsminrange: 300,
  obsmaxrange: 7200,
  changeObjectInterval: 1000,
  country: 'Russia',
  sun: 'No Impact',
  volume: false,
};
sensorList.PIO = {
  name: 'Pionersky, Russia',
  shortName: 'PIO',
  type: 'Phased Array Radar',
  lat: 54.857294,
  lon: 20.18235,
  alt: 0.0,
  obsminaz: 187.5, // All Information via russianforces.org
  obsmaxaz: 292.5,
  obsminel: 2,
  obsmaxel: 60,
  obsminrange: 100,
  obsmaxrange: 4200,
  changeObjectInterval: 1000,
  country: 'Russia',
  sun: 'No Impact',
  volume: false,
};
sensorList.XUA = {
  name: 'Xuanhua, China',
  shortName: 'XUA',
  type: 'Phased Array Radar',
  lat: 40.446944,
  lon: 115.116389,
  alt: 1.6,
  obsminaz: 300, // Information via global ssa sensors amos 2010.pdf (sinodefence.com/special/airdefense/project640.asp)
  obsmaxaz: 60, // Information via global ssa sensors amos 2010.pdf (sinodefence.com/special/airdefense/project640.asp)
  obsminel: 2, // Information via globalsecurity.org
  obsmaxel: 80, // Information via globalsecurity.org
  obsminrange: 300,
  obsmaxrange: 3000, // Information via global ssa sensors amos 2010.pdf (sinodefence.com/special/airdefense/project640.asp)
  changeObjectInterval: 1000,
  country: 'China',
  sun: 'No Impact',
  volume: false,
};

// Telescopes
sensorList.MLS = {
  name: 'Mount Lemmon Survey, Arizona',
  shortName: 'MLS',
  type: 'Optical',
  lat: 32.442,
  lon: -110.789,
  alt: 2.791,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 10,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 200000,
  changeObjectInterval: 20000,
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};

sensorList.PMO = {
  name: 'Purple Mountain Observatory, China',
  shortName: 'PMO',
  type: 'Optical',
  lat: 32.064946,
  lon: 118.829677,
  alt: 0.267,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 10,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 200000,
  changeObjectInterval: 20000,
  country: 'China',
  sun: 'No Impact',
  volume: false,
};

sensorList.PO = {
  name: 'Palomar Observatory, California',
  shortName: 'PO',
  type: 'Optical',
  lat: 33.3564,
  lon: -116.865,
  alt: 1.712,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 10,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 200000,
  changeObjectInterval: 20000,
  country: 'United States',
  sun: 'No Impact',
  volume: false,
};

sensorList.LSO = {
  name: 'La Sagra Observatory, Spain',
  shortName: 'LSO',
  type: 'Optical',
  lat: 37.9839,
  lon: -2.5644,
  alt: 0,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 10,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 200000,
  changeObjectInterval: 20000,
  country: 'Spain',
  sun: 'No Impact',
  volume: false,
};

// ISON Sensors
sensorList.MAY = {
  name: 'Mayhill, New Mexico',
  shortName: 'MAY',
  type: 'Optical',
  lat: 32.9039,
  lon: -105.5289,
  alt: 2.225,
  obsminaz: 0,
  obsmaxaz: 360,
  obsminel: 10,
  obsmaxel: 90,
  obsminrange: 0,
  obsmaxrange: 200000,
  changeObjectInterval: 20000,
  country: 'USA',
  sun: 'No Impact',
  volume: false,
};
sensorList.TAI = {
  name: 'Surveillance Radar Program, Taiwan',
  shortName: 'TAI',
  type: 'Phased Array Radar',
  lat: 24.499,
  lon: 121.072,
  alt: 0.060966, // Find accurate altitude
  obsminaz: 180,
  obsmaxaz: 60,
  obsminel: 3,
  obsmaxel: 85,
  obsminrange: 200,
  obsmaxrange: 5556,
  changeObjectInterval: 1000,
  linkAehf: false,
  linkWgs: false,
  zoom: 'leo',
  url: 'https://fas.org/man/eprint/leshan.pdf',
  country: 'Taiwan',
  sun: 'No Impact',
  volume: false,
};

export { sensorList };
