!(function t(e, n, r) {
    function o(l, s) {
        if (!n[l]) {
            if (!e[l]) {
                var a = 'function' == typeof require && require;
                if (!s && a) return a(l, !0);
                if (i) return i(l, !0);
                var c = new Error("Cannot find module '" + l + "'");
                throw ((c.code = 'MODULE_NOT_FOUND'), c);
            }
            var u = (n[l] = { exports: {} });
            e[l][0].call(
                u.exports,
                function (t) {
                    var n = e[l][1][t];
                    return o(n || t);
                },
                u,
                u.exports,
                t,
                e,
                n,
                r
            );
        }
        return n[l].exports;
    }
    for (
        var i = 'function' == typeof require && require, l = 0;
        l < r.length;
        l++
    )
        o(r[l]);
    return o;
})(
    {
        1: [
            function (t, e, n) {
                'use strict';
                function r(t) {
                    t.fn.perfectScrollbar = function (e) {
                        return this.each(function () {
                            if ('object' == typeof e || void 0 === e) {
                                var n = e;
                                i.get(this) || o.initialize(this, n);
                            } else {
                                var r = e;
                                'update' === r
                                    ? o.update(this)
                                    : 'destroy' === r && o.destroy(this);
                            }
                            return t(this);
                        });
                    };
                }
                var o = t('../main'),
                    i = t('../plugin/instances');
                if ('function' == typeof define && define.amd)
                    define(['jquery'], r);
                else {
                    var l = window.jQuery ? window.jQuery : window.$;
                    void 0 !== l && r(l);
                }
                e.exports = r;
            },
            { '../main': 7, '../plugin/instances': 18 },
        ],
        2: [
            function (t, e, n) {
                'use strict';
                (n.add = function (t, e) {
                    t.classList
                        ? t.classList.add(e)
                        : (function (t, e) {
                              var n = t.className.split(' ');
                              n.indexOf(e) < 0 && n.push(e),
                                  (t.className = n.join(' '));
                          })(t, e);
                }),
                    (n.remove = function (t, e) {
                        t.classList
                            ? t.classList.remove(e)
                            : (function (t, e) {
                                  var n = t.className.split(' '),
                                      r = n.indexOf(e);
                                  r >= 0 && n.splice(r, 1),
                                      (t.className = n.join(' '));
                              })(t, e);
                    }),
                    (n.list = function (t) {
                        return t.classList
                            ? Array.prototype.slice.apply(t.classList)
                            : t.className.split(' ');
                    });
            },
            {},
        ],
        3: [
            function (t, e, n) {
                'use strict';
                var r = {
                    e: function (t, e) {
                        var n = document.createElement(t);
                        return (n.className = e), n;
                    },
                    appendTo: function (t, e) {
                        return e.appendChild(t), t;
                    },
                };
                (r.css = function (t, e, n) {
                    return 'object' == typeof e
                        ? (function (t, e) {
                              for (var n in e) {
                                  var r = e[n];
                                  'number' == typeof r &&
                                      (r = r.toString() + 'px'),
                                      (t.style[n] = r);
                              }
                              return t;
                          })(t, e)
                        : void 0 === n
                        ? (function (t, e) {
                              return window.getComputedStyle(t)[e];
                          })(t, e)
                        : (function (t, e, n) {
                              return (
                                  'number' == typeof n &&
                                      (n = n.toString() + 'px'),
                                  (t.style[e] = n),
                                  t
                              );
                          })(t, e, n);
                }),
                    (r.matches = function (t, e) {
                        return void 0 !== t.matches
                            ? t.matches(e)
                            : void 0 !== t.matchesSelector
                            ? t.matchesSelector(e)
                            : void 0 !== t.webkitMatchesSelector
                            ? t.webkitMatchesSelector(e)
                            : void 0 !== t.mozMatchesSelector
                            ? t.mozMatchesSelector(e)
                            : void 0 !== t.msMatchesSelector
                            ? t.msMatchesSelector(e)
                            : void 0;
                    }),
                    (r.remove = function (t) {
                        void 0 !== t.remove
                            ? t.remove()
                            : t.parentNode && t.parentNode.removeChild(t);
                    }),
                    (r.queryChildren = function (t, e) {
                        return Array.prototype.filter.call(
                            t.childNodes,
                            function (t) {
                                return r.matches(t, e);
                            }
                        );
                    }),
                    (e.exports = r);
            },
            {},
        ],
        4: [
            function (t, e, n) {
                'use strict';
                var r = function (t) {
                    (this.element = t), (this.events = {});
                };
                (r.prototype.bind = function (t, e) {
                    void 0 === this.events[t] && (this.events[t] = []),
                        this.events[t].push(e),
                        this.element.addEventListener(t, e, !1);
                }),
                    (r.prototype.unbind = function (t, e) {
                        var n = void 0 !== e;
                        this.events[t] = this.events[t].filter(function (r) {
                            return (
                                !(!n || r === e) ||
                                (this.element.removeEventListener(t, r, !1), !1)
                            );
                        }, this);
                    }),
                    (r.prototype.unbindAll = function () {
                        for (var t in this.events) this.unbind(t);
                    });
                var o = function () {
                    this.eventElements = [];
                };
                (o.prototype.eventElement = function (t) {
                    var e = this.eventElements.filter(function (e) {
                        return e.element === t;
                    })[0];
                    return (
                        void 0 === e &&
                            ((e = new r(t)), this.eventElements.push(e)),
                        e
                    );
                }),
                    (o.prototype.bind = function (t, e, n) {
                        this.eventElement(t).bind(e, n);
                    }),
                    (o.prototype.unbind = function (t, e, n) {
                        this.eventElement(t).unbind(e, n);
                    }),
                    (o.prototype.unbindAll = function () {
                        for (var t = 0; t < this.eventElements.length; t++)
                            this.eventElements[t].unbindAll();
                    }),
                    (o.prototype.once = function (t, e, n) {
                        var r = this.eventElement(t),
                            o = function (t) {
                                r.unbind(e, o), n(t);
                            };
                        r.bind(e, o);
                    }),
                    (e.exports = o);
            },
            {},
        ],
        5: [
            function (t, e, n) {
                'use strict';
                e.exports = (function () {
                    function t() {
                        return Math.floor(65536 * (1 + Math.random()))
                            .toString(16)
                            .substring(1);
                    }
                    return function () {
                        return (
                            t() +
                            t() +
                            '-' +
                            t() +
                            '-' +
                            t() +
                            '-' +
                            t() +
                            '-' +
                            t() +
                            t() +
                            t()
                        );
                    };
                })();
            },
            {},
        ],
        6: [
            function (t, e, n) {
                'use strict';
                var r = t('./class'),
                    o = t('./dom');
                (n.toInt = function (t) {
                    return parseInt(t, 10) || 0;
                }),
                    (n.clone = function (t) {
                        if (null === t) return null;
                        if ('object' == typeof t) {
                            var e = {};
                            for (var n in t) e[n] = this.clone(t[n]);
                            return e;
                        }
                        return t;
                    }),
                    (n.extend = function (t, e) {
                        var n = this.clone(t);
                        for (var r in e) n[r] = this.clone(e[r]);
                        return n;
                    }),
                    (n.isEditable = function (t) {
                        return (
                            o.matches(t, 'input,[contenteditable]') ||
                            o.matches(t, 'select,[contenteditable]') ||
                            o.matches(t, 'textarea,[contenteditable]') ||
                            o.matches(t, 'button,[contenteditable]')
                        );
                    }),
                    (n.removePsClasses = function (t) {
                        for (var e = r.list(t), n = 0; n < e.length; n++) {
                            var o = e[n];
                            0 === o.indexOf('ps-') && r.remove(t, o);
                        }
                    }),
                    (n.outerWidth = function (t) {
                        return (
                            this.toInt(o.css(t, 'width')) +
                            this.toInt(o.css(t, 'paddingLeft')) +
                            this.toInt(o.css(t, 'paddingRight')) +
                            this.toInt(o.css(t, 'borderLeftWidth')) +
                            this.toInt(o.css(t, 'borderRightWidth'))
                        );
                    }),
                    (n.startScrolling = function (t, e) {
                        r.add(t, 'ps-in-scrolling'),
                            void 0 !== e
                                ? r.add(t, 'ps-' + e)
                                : (r.add(t, 'ps-x'), r.add(t, 'ps-y'));
                    }),
                    (n.stopScrolling = function (t, e) {
                        r.remove(t, 'ps-in-scrolling'),
                            void 0 !== e
                                ? r.remove(t, 'ps-' + e)
                                : (r.remove(t, 'ps-x'), r.remove(t, 'ps-y'));
                    }),
                    (n.env = {
                        isWebKit:
                            'WebkitAppearance' in
                            document.documentElement.style,
                        supportsTouch:
                            'ontouchstart' in window ||
                            (window.DocumentTouch &&
                                document instanceof window.DocumentTouch),
                        supportsIePointer:
                            null !== window.navigator.msMaxTouchPoints,
                    });
            },
            { './class': 2, './dom': 3 },
        ],
        7: [
            function (t, e, n) {
                'use strict';
                var r = t('./plugin/destroy'),
                    o = t('./plugin/initialize'),
                    i = t('./plugin/update');
                e.exports = { initialize: o, update: i, destroy: r };
            },
            {
                './plugin/destroy': 9,
                './plugin/initialize': 17,
                './plugin/update': 21,
            },
        ],
        8: [
            function (t, e, n) {
                'use strict';
                e.exports = {
                    maxScrollbarLength: null,
                    minScrollbarLength: null,
                    scrollXMarginOffset: 0,
                    scrollYMarginOffset: 0,
                    stopPropagationOnClick: !0,
                    suppressScrollX: !1,
                    suppressScrollY: !1,
                    swipePropagation: !0,
                    useBothWheelAxes: !1,
                    useKeyboard: !0,
                    useSelectionScroll: !1,
                    wheelPropagation: !1,
                    wheelSpeed: 1,
                };
            },
            {},
        ],
        9: [
            function (t, e, n) {
                'use strict';
                var r = t('../lib/dom'),
                    o = t('../lib/helper'),
                    i = t('./instances');
                e.exports = function (t) {
                    var e = i.get(t);
                    e &&
                        (e.event.unbindAll(),
                        r.remove(e.scrollbarX),
                        r.remove(e.scrollbarY),
                        r.remove(e.scrollbarXRail),
                        r.remove(e.scrollbarYRail),
                        o.removePsClasses(t),
                        i.remove(t));
                };
            },
            { '../lib/dom': 3, '../lib/helper': 6, './instances': 18 },
        ],
        10: [
            function (t, e, n) {
                'use strict';
                var r = t('../../lib/helper'),
                    o = t('../instances'),
                    i = t('../update-geometry'),
                    l = t('../update-scroll');
                e.exports = function (t) {
                    !(function (t, e) {
                        function n(t) {
                            return t.getBoundingClientRect();
                        }
                        var o = window.Event.prototype.stopPropagation.bind;
                        e.settings.stopPropagationOnClick &&
                            e.event.bind(e.scrollbarY, 'click', o),
                            e.event.bind(e.scrollbarYRail, 'click', function (
                                o
                            ) {
                                var s = r.toInt(e.scrollbarYHeight / 2),
                                    a =
                                        (e.railYRatio *
                                            (o.pageY -
                                                window.pageYOffset -
                                                n(e.scrollbarYRail).top -
                                                s)) /
                                        (e.railYRatio *
                                            (e.railYHeight -
                                                e.scrollbarYHeight));
                                0 > a ? (a = 0) : a > 1 && (a = 1),
                                    l(
                                        t,
                                        'top',
                                        (e.contentHeight - e.containerHeight) *
                                            a
                                    ),
                                    i(t),
                                    o.stopPropagation();
                            }),
                            e.settings.stopPropagationOnClick &&
                                e.event.bind(e.scrollbarX, 'click', o),
                            e.event.bind(e.scrollbarXRail, 'click', function (
                                o
                            ) {
                                var s = r.toInt(e.scrollbarXWidth / 2),
                                    a =
                                        (e.railXRatio *
                                            (o.pageX -
                                                window.pageXOffset -
                                                n(e.scrollbarXRail).left -
                                                s)) /
                                        (e.railXRatio *
                                            (e.railXWidth - e.scrollbarXWidth));
                                0 > a ? (a = 0) : a > 1 && (a = 1),
                                    l(
                                        t,
                                        'left',
                                        (e.contentWidth - e.containerWidth) *
                                            a -
                                            e.negativeScrollAdjustment
                                    ),
                                    i(t),
                                    o.stopPropagation();
                            });
                    })(t, o.get(t));
                };
            },
            {
                '../../lib/helper': 6,
                '../instances': 18,
                '../update-geometry': 19,
                '../update-scroll': 20,
            },
        ],
        11: [
            function (t, e, n) {
                'use strict';
                var r = t('../../lib/dom'),
                    o = t('../../lib/helper'),
                    i = t('../instances'),
                    l = t('../update-geometry'),
                    s = t('../update-scroll');
                e.exports = function (t) {
                    var e = i.get(t);
                    (function (t, e) {
                        function n(n) {
                            var r = i + n * e.railXRatio,
                                l =
                                    Math.max(
                                        0,
                                        e.scrollbarXRail.getBoundingClientRect()
                                            .left
                                    ) +
                                    e.railXRatio *
                                        (e.railXWidth - e.scrollbarXWidth);
                            e.scrollbarXLeft = 0 > r ? 0 : r > l ? l : r;
                            var a =
                                o.toInt(
                                    (e.scrollbarXLeft *
                                        (e.contentWidth - e.containerWidth)) /
                                        (e.containerWidth -
                                            e.railXRatio * e.scrollbarXWidth)
                                ) - e.negativeScrollAdjustment;
                            s(t, 'left', a);
                        }
                        var i = null,
                            a = null,
                            c = function (e) {
                                n(e.pageX - a),
                                    l(t),
                                    e.stopPropagation(),
                                    e.preventDefault();
                            },
                            u = function () {
                                o.stopScrolling(t, 'x'),
                                    e.event.unbind(
                                        e.ownerDocument,
                                        'mousemove',
                                        c
                                    );
                            };
                        e.event.bind(e.scrollbarX, 'mousedown', function (n) {
                            (a = n.pageX),
                                (i =
                                    o.toInt(r.css(e.scrollbarX, 'left')) *
                                    e.railXRatio),
                                o.startScrolling(t, 'x'),
                                e.event.bind(e.ownerDocument, 'mousemove', c),
                                e.event.once(e.ownerDocument, 'mouseup', u),
                                n.stopPropagation(),
                                n.preventDefault();
                        });
                    })(t, e),
                        (function (t, e) {
                            function n(n) {
                                var r = i + n * e.railYRatio,
                                    l =
                                        Math.max(
                                            0,
                                            e.scrollbarYRail.getBoundingClientRect()
                                                .top
                                        ) +
                                        e.railYRatio *
                                            (e.railYHeight -
                                                e.scrollbarYHeight);
                                e.scrollbarYTop = 0 > r ? 0 : r > l ? l : r;
                                var a = o.toInt(
                                    (e.scrollbarYTop *
                                        (e.contentHeight - e.containerHeight)) /
                                        (e.containerHeight -
                                            e.railYRatio * e.scrollbarYHeight)
                                );
                                s(t, 'top', a);
                            }
                            var i = null,
                                a = null,
                                c = function (e) {
                                    n(e.pageY - a),
                                        l(t),
                                        e.stopPropagation(),
                                        e.preventDefault();
                                },
                                u = function () {
                                    o.stopScrolling(t, 'y'),
                                        e.event.unbind(
                                            e.ownerDocument,
                                            'mousemove',
                                            c
                                        );
                                };
                            e.event.bind(e.scrollbarY, 'mousedown', function (
                                n
                            ) {
                                (a = n.pageY),
                                    (i =
                                        o.toInt(r.css(e.scrollbarY, 'top')) *
                                        e.railYRatio),
                                    o.startScrolling(t, 'y'),
                                    e.event.bind(
                                        e.ownerDocument,
                                        'mousemove',
                                        c
                                    ),
                                    e.event.once(e.ownerDocument, 'mouseup', u),
                                    n.stopPropagation(),
                                    n.preventDefault();
                            });
                        })(t, e);
                };
            },
            {
                '../../lib/dom': 3,
                '../../lib/helper': 6,
                '../instances': 18,
                '../update-geometry': 19,
                '../update-scroll': 20,
            },
        ],
        12: [
            function (t, e, n) {
                'use strict';
                function r(t, e) {
                    var n = !1;
                    e.event.bind(t, 'mouseenter', function () {
                        n = !0;
                    }),
                        e.event.bind(t, 'mouseleave', function () {
                            n = !1;
                        });
                    e.event.bind(e.ownerDocument, 'keydown', function (r) {
                        if (
                            (!r.isDefaultPrevented ||
                                !r.isDefaultPrevented()) &&
                            n
                        ) {
                            var i = document.activeElement
                                ? document.activeElement
                                : e.ownerDocument.activeElement;
                            if (i) {
                                for (; i.shadowRoot; )
                                    i = i.shadowRoot.activeElement;
                                if (o.isEditable(i)) return;
                            }
                            var a = 0,
                                c = 0;
                            switch (r.which) {
                                case 37:
                                    a = -30;
                                    break;
                                case 38:
                                    c = 30;
                                    break;
                                case 39:
                                    a = 30;
                                    break;
                                case 40:
                                    c = -30;
                                    break;
                                case 33:
                                    c = 90;
                                    break;
                                case 32:
                                    c = r.shiftKey ? 90 : -90;
                                    break;
                                case 34:
                                    c = -90;
                                    break;
                                case 35:
                                    c = r.ctrlKey
                                        ? -e.contentHeight
                                        : -e.containerHeight;
                                    break;
                                case 36:
                                    c = r.ctrlKey
                                        ? t.scrollTop
                                        : e.containerHeight;
                                    break;
                                default:
                                    return;
                            }
                            s(t, 'top', t.scrollTop - c),
                                s(t, 'left', t.scrollLeft + a),
                                l(t),
                                (function (n, r) {
                                    var o = t.scrollTop;
                                    if (0 === n) {
                                        if (!e.scrollbarYActive) return !1;
                                        if (
                                            (0 === o && r > 0) ||
                                            (o >=
                                                e.contentHeight -
                                                    e.containerHeight &&
                                                0 > r)
                                        )
                                            return !e.settings.wheelPropagation;
                                    }
                                    var i = t.scrollLeft;
                                    if (0 === r) {
                                        if (!e.scrollbarXActive) return !1;
                                        if (
                                            (0 === i && 0 > n) ||
                                            (i >=
                                                e.contentWidth -
                                                    e.containerWidth &&
                                                n > 0)
                                        )
                                            return !e.settings.wheelPropagation;
                                    }
                                    return !0;
                                })(a, c) && r.preventDefault();
                        }
                    });
                }
                var o = t('../../lib/helper'),
                    i = t('../instances'),
                    l = t('../update-geometry'),
                    s = t('../update-scroll');
                e.exports = function (t) {
                    r(t, i.get(t));
                };
            },
            {
                '../../lib/helper': 6,
                '../instances': 18,
                '../update-geometry': 19,
                '../update-scroll': 20,
            },
        ],
        13: [
            function (t, e, n) {
                'use strict';
                function r(t, e) {
                    function n(n) {
                        var o = (function (t) {
                                var e = t.deltaX,
                                    n = -1 * t.deltaY;
                                return (
                                    (void 0 === e || void 0 === n) &&
                                        ((e = (-1 * t.wheelDeltaX) / 6),
                                        (n = t.wheelDeltaY / 6)),
                                    t.deltaMode &&
                                        1 === t.deltaMode &&
                                        ((e *= 10), (n *= 10)),
                                    e != e &&
                                        n != n &&
                                        ((e = 0), (n = t.wheelDelta)),
                                    [e, n]
                                );
                            })(n),
                            s = o[0],
                            a = o[1];
                        (function (e, n) {
                            var r = t.querySelector('textarea:hover');
                            if (r) {
                                var o = r.scrollHeight - r.clientHeight;
                                if (
                                    o > 0 &&
                                    !(
                                        (0 === r.scrollTop && n > 0) ||
                                        (r.scrollTop === o && 0 > n)
                                    )
                                )
                                    return !0;
                                var i = r.scrollLeft - r.clientWidth;
                                if (
                                    i > 0 &&
                                    !(
                                        (0 === r.scrollLeft && 0 > e) ||
                                        (r.scrollLeft === i && e > 0)
                                    )
                                )
                                    return !0;
                            }
                            return !1;
                        })(s, a) ||
                            ((r = !1),
                            e.settings.useBothWheelAxes
                                ? e.scrollbarYActive && !e.scrollbarXActive
                                    ? (l(
                                          t,
                                          'top',
                                          a
                                              ? t.scrollTop -
                                                    a * e.settings.wheelSpeed
                                              : t.scrollTop +
                                                    s * e.settings.wheelSpeed
                                      ),
                                      (r = !0))
                                    : e.scrollbarXActive &&
                                      !e.scrollbarYActive &&
                                      (l(
                                          t,
                                          'left',
                                          s
                                              ? t.scrollLeft +
                                                    s * e.settings.wheelSpeed
                                              : t.scrollLeft -
                                                    a * e.settings.wheelSpeed
                                      ),
                                      (r = !0))
                                : (l(
                                      t,
                                      'top',
                                      t.scrollTop - a * e.settings.wheelSpeed
                                  ),
                                  l(
                                      t,
                                      'left',
                                      t.scrollLeft + s * e.settings.wheelSpeed
                                  )),
                            i(t),
                            (r =
                                r ||
                                (function (n, r) {
                                    var o = t.scrollTop;
                                    if (0 === n) {
                                        if (!e.scrollbarYActive) return !1;
                                        if (
                                            (0 === o && r > 0) ||
                                            (o >=
                                                e.contentHeight -
                                                    e.containerHeight &&
                                                0 > r)
                                        )
                                            return !e.settings.wheelPropagation;
                                    }
                                    var i = t.scrollLeft;
                                    if (0 === r) {
                                        if (!e.scrollbarXActive) return !1;
                                        if (
                                            (0 === i && 0 > n) ||
                                            (i >=
                                                e.contentWidth -
                                                    e.containerWidth &&
                                                n > 0)
                                        )
                                            return !e.settings.wheelPropagation;
                                    }
                                    return !0;
                                })(s, a)) &&
                                (n.stopPropagation(), n.preventDefault()));
                    }
                    var r = !1;
                    void 0 !== window.onwheel
                        ? e.event.bind(t, 'wheel', n)
                        : void 0 !== window.onmousewheel &&
                          e.event.bind(t, 'mousewheel', n);
                }
                var o = t('../instances'),
                    i = t('../update-geometry'),
                    l = t('../update-scroll');
                e.exports = function (t) {
                    r(t, o.get(t));
                };
            },
            {
                '../instances': 18,
                '../update-geometry': 19,
                '../update-scroll': 20,
            },
        ],
        14: [
            function (t, e, n) {
                'use strict';
                var r = t('../instances'),
                    o = t('../update-geometry');
                e.exports = function (t) {
                    !(function (t, e) {
                        e.event.bind(t, 'scroll', function () {
                            o(t);
                        });
                    })(t, r.get(t));
                };
            },
            { '../instances': 18, '../update-geometry': 19 },
        ],
        15: [
            function (t, e, n) {
                'use strict';
                function r(t, e) {
                    function n() {
                        a ||
                            (a = setInterval(function () {
                                return i.get(t)
                                    ? (s(t, 'top', t.scrollTop + c.top),
                                      s(t, 'left', t.scrollLeft + c.left),
                                      void l(t))
                                    : void clearInterval(a);
                            }, 50));
                    }
                    function r() {
                        a && (clearInterval(a), (a = null)), o.stopScrolling(t);
                    }
                    var a = null,
                        c = { top: 0, left: 0 },
                        u = !1;
                    e.event.bind(
                        e.ownerDocument,
                        'selectionchange',
                        function () {
                            t.contains(
                                (function () {
                                    var t = window.getSelection
                                        ? window.getSelection()
                                        : document.getSelection
                                        ? document.getSelection()
                                        : '';
                                    return 0 === t.toString().length
                                        ? null
                                        : t.getRangeAt(0)
                                              .commonAncestorContainer;
                                })()
                            )
                                ? (u = !0)
                                : ((u = !1), r());
                        }
                    ),
                        e.event.bind(window, 'mouseup', function () {
                            u && ((u = !1), r());
                        }),
                        e.event.bind(window, 'mousemove', function (e) {
                            if (u) {
                                var i = { x: e.pageX, y: e.pageY },
                                    l = {
                                        left: t.offsetLeft,
                                        right: t.offsetLeft + t.offsetWidth,
                                        top: t.offsetTop,
                                        bottom: t.offsetTop + t.offsetHeight,
                                    };
                                i.x < l.left + 3
                                    ? ((c.left = -5), o.startScrolling(t, 'x'))
                                    : i.x > l.right - 3
                                    ? ((c.left = 5), o.startScrolling(t, 'x'))
                                    : (c.left = 0),
                                    i.y < l.top + 3
                                        ? ((c.top =
                                              l.top + 3 - i.y < 5 ? -5 : -20),
                                          o.startScrolling(t, 'y'))
                                        : i.y > l.bottom - 3
                                        ? ((c.top =
                                              i.y - l.bottom + 3 < 5 ? 5 : 20),
                                          o.startScrolling(t, 'y'))
                                        : (c.top = 0),
                                    0 === c.top && 0 === c.left ? r() : n();
                            }
                        });
                }
                var o = t('../../lib/helper'),
                    i = t('../instances'),
                    l = t('../update-geometry'),
                    s = t('../update-scroll');
                e.exports = function (t) {
                    r(t, i.get(t));
                };
            },
            {
                '../../lib/helper': 6,
                '../instances': 18,
                '../update-geometry': 19,
                '../update-scroll': 20,
            },
        ],
        16: [
            function (t, e, n) {
                'use strict';
                function r(t, e, n, r) {
                    function s(n, r) {
                        var o = t.scrollTop,
                            i = t.scrollLeft,
                            l = Math.abs(n),
                            s = Math.abs(r);
                        if (s > l) {
                            if (
                                (0 > r &&
                                    o ===
                                        e.contentHeight - e.containerHeight) ||
                                (r > 0 && 0 === o)
                            )
                                return !e.settings.swipePropagation;
                        } else if (
                            l > s &&
                            ((0 > n &&
                                i === e.contentWidth - e.containerWidth) ||
                                (n > 0 && 0 === i))
                        )
                            return !e.settings.swipePropagation;
                        return !0;
                    }
                    function a(e, n) {
                        l(t, 'top', t.scrollTop - n),
                            l(t, 'left', t.scrollLeft - e),
                            i(t);
                    }
                    function c() {
                        w = !0;
                    }
                    function u() {
                        w = !1;
                    }
                    function d(t) {
                        return t.targetTouches ? t.targetTouches[0] : t;
                    }
                    function p(t) {
                        return (
                            !(
                                !t.targetTouches || 1 !== t.targetTouches.length
                            ) ||
                            !(
                                !t.pointerType ||
                                'mouse' === t.pointerType ||
                                t.pointerType === t.MSPOINTER_TYPE_MOUSE
                            )
                        );
                    }
                    function h(t) {
                        if (p(t)) {
                            X = !0;
                            var e = d(t);
                            (b.pageX = e.pageX),
                                (b.pageY = e.pageY),
                                (g = new Date().getTime()),
                                null !== Y && clearInterval(Y),
                                t.stopPropagation();
                        }
                    }
                    function f(t) {
                        if (!w && X && p(t)) {
                            var e = d(t),
                                n = { pageX: e.pageX, pageY: e.pageY },
                                r = n.pageX - b.pageX,
                                o = n.pageY - b.pageY;
                            a(r, o), (b = n);
                            var i = new Date().getTime(),
                                l = i - g;
                            l > 0 && ((m.x = r / l), (m.y = o / l), (g = i)),
                                s(r, o) &&
                                    (t.stopPropagation(), t.preventDefault());
                        }
                    }
                    function v() {
                        !w &&
                            X &&
                            ((X = !1),
                            clearInterval(Y),
                            (Y = setInterval(function () {
                                return o.get(t)
                                    ? Math.abs(m.x) < 0.01 &&
                                      Math.abs(m.y) < 0.01
                                        ? void clearInterval(Y)
                                        : (a(30 * m.x, 30 * m.y),
                                          (m.x *= 0.8),
                                          void (m.y *= 0.8))
                                    : void clearInterval(Y);
                            }, 10)));
                    }
                    var b = {},
                        g = 0,
                        m = {},
                        Y = null,
                        w = !1,
                        X = !1;
                    n &&
                        (e.event.bind(window, 'touchstart', c),
                        e.event.bind(window, 'touchend', u),
                        e.event.bind(t, 'touchstart', h),
                        e.event.bind(t, 'touchmove', f),
                        e.event.bind(t, 'touchend', v)),
                        r &&
                            (window.PointerEvent
                                ? (e.event.bind(window, 'pointerdown', c),
                                  e.event.bind(window, 'pointerup', u),
                                  e.event.bind(t, 'pointerdown', h),
                                  e.event.bind(t, 'pointermove', f),
                                  e.event.bind(t, 'pointerup', v))
                                : window.MSPointerEvent &&
                                  (e.event.bind(window, 'MSPointerDown', c),
                                  e.event.bind(window, 'MSPointerUp', u),
                                  e.event.bind(t, 'MSPointerDown', h),
                                  e.event.bind(t, 'MSPointerMove', f),
                                  e.event.bind(t, 'MSPointerUp', v)));
                }
                var o = t('../instances'),
                    i = t('../update-geometry'),
                    l = t('../update-scroll');
                e.exports = function (t, e, n) {
                    r(t, o.get(t), e, n);
                };
            },
            {
                '../instances': 18,
                '../update-geometry': 19,
                '../update-scroll': 20,
            },
        ],
        17: [
            function (t, e, n) {
                'use strict';
                var r = t('../lib/class'),
                    o = t('../lib/helper'),
                    i = t('./instances'),
                    l = t('./update-geometry'),
                    s = t('./handler/click-rail'),
                    a = t('./handler/drag-scrollbar'),
                    c = t('./handler/keyboard'),
                    u = t('./handler/mouse-wheel'),
                    d = t('./handler/native-scroll'),
                    p = t('./handler/selection'),
                    h = t('./handler/touch');
                e.exports = function (t, e) {
                    (e = 'object' == typeof e ? e : {}),
                        r.add(t, 'ps-container');
                    var n = i.add(t);
                    (n.settings = o.extend(n.settings, e)),
                        s(t),
                        a(t),
                        u(t),
                        d(t),
                        n.settings.useSelectionScroll && p(t),
                        (o.env.supportsTouch || o.env.supportsIePointer) &&
                            h(t, o.env.supportsTouch, o.env.supportsIePointer),
                        n.settings.useKeyboard && c(t),
                        l(t);
                };
            },
            {
                '../lib/class': 2,
                '../lib/helper': 6,
                './handler/click-rail': 10,
                './handler/drag-scrollbar': 11,
                './handler/keyboard': 12,
                './handler/mouse-wheel': 13,
                './handler/native-scroll': 14,
                './handler/selection': 15,
                './handler/touch': 16,
                './instances': 18,
                './update-geometry': 19,
            },
        ],
        18: [
            function (t, e, n) {
                'use strict';
                function r(t) {
                    var e = this;
                    (e.settings = c.clone(l)),
                        (e.containerWidth = null),
                        (e.containerHeight = null),
                        (e.contentWidth = null),
                        (e.contentHeight = null),
                        (e.isRtl = 'rtl' === i.css(t, 'direction')),
                        (e.isNegativeScroll = (function () {
                            var e,
                                n = t.scrollLeft;
                            return (
                                (t.scrollLeft = -1),
                                (e = t.scrollLeft < 0),
                                (t.scrollLeft = n),
                                e
                            );
                        })()),
                        (e.negativeScrollAdjustment = e.isNegativeScroll
                            ? t.scrollWidth - t.clientWidth
                            : 0),
                        (e.event = new s()),
                        (e.ownerDocument = t.ownerDocument || document),
                        (e.scrollbarXRail = i.appendTo(
                            i.e('div', 'ps-scrollbar-x-rail'),
                            t
                        )),
                        (e.scrollbarX = i.appendTo(
                            i.e('div', 'ps-scrollbar-x'),
                            e.scrollbarXRail
                        )),
                        e.scrollbarX.setAttribute('tabindex', 0),
                        (e.scrollbarXActive = null),
                        (e.scrollbarXWidth = null),
                        (e.scrollbarXLeft = null),
                        (e.scrollbarXBottom = c.toInt(
                            i.css(e.scrollbarXRail, 'bottom')
                        )),
                        (e.isScrollbarXUsingBottom =
                            e.scrollbarXBottom == e.scrollbarXBottom),
                        (e.scrollbarXTop = e.isScrollbarXUsingBottom
                            ? null
                            : c.toInt(i.css(e.scrollbarXRail, 'top'))),
                        (e.railBorderXWidth =
                            c.toInt(
                                i.css(e.scrollbarXRail, 'borderLeftWidth')
                            ) +
                            c.toInt(
                                i.css(e.scrollbarXRail, 'borderRightWidth')
                            )),
                        i.css(e.scrollbarXRail, 'display', 'block'),
                        (e.railXMarginWidth =
                            c.toInt(i.css(e.scrollbarXRail, 'marginLeft')) +
                            c.toInt(i.css(e.scrollbarXRail, 'marginRight'))),
                        i.css(e.scrollbarXRail, 'display', ''),
                        (e.railXWidth = null),
                        (e.railXRatio = null),
                        (e.scrollbarYRail = i.appendTo(
                            i.e('div', 'ps-scrollbar-y-rail'),
                            t
                        )),
                        (e.scrollbarY = i.appendTo(
                            i.e('div', 'ps-scrollbar-y'),
                            e.scrollbarYRail
                        )),
                        e.scrollbarY.setAttribute('tabindex', 0),
                        (e.scrollbarYActive = null),
                        (e.scrollbarYHeight = null),
                        (e.scrollbarYTop = null),
                        (e.scrollbarYRight = c.toInt(
                            i.css(e.scrollbarYRail, 'right')
                        )),
                        (e.isScrollbarYUsingRight =
                            e.scrollbarYRight == e.scrollbarYRight),
                        (e.scrollbarYLeft = e.isScrollbarYUsingRight
                            ? null
                            : c.toInt(i.css(e.scrollbarYRail, 'left'))),
                        (e.scrollbarYOuterWidth = e.isRtl
                            ? c.outerWidth(e.scrollbarY)
                            : null),
                        (e.railBorderYWidth =
                            c.toInt(i.css(e.scrollbarYRail, 'borderTopWidth')) +
                            c.toInt(
                                i.css(e.scrollbarYRail, 'borderBottomWidth')
                            )),
                        i.css(e.scrollbarYRail, 'display', 'block'),
                        (e.railYMarginHeight =
                            c.toInt(i.css(e.scrollbarYRail, 'marginTop')) +
                            c.toInt(i.css(e.scrollbarYRail, 'marginBottom'))),
                        i.css(e.scrollbarYRail, 'display', ''),
                        (e.railYHeight = null),
                        (e.railYRatio = null);
                }
                function o(t) {
                    return void 0 === t.dataset
                        ? t.getAttribute('data-ps-id')
                        : t.dataset.psId;
                }
                var i = t('../lib/dom'),
                    l = t('./default-setting'),
                    s = t('../lib/event-manager'),
                    a = t('../lib/guid'),
                    c = t('../lib/helper'),
                    u = {};
                (n.add = function (t) {
                    var e = a();
                    return (
                        (function (t, e) {
                            void 0 === t.dataset
                                ? t.setAttribute('data-ps-id', e)
                                : (t.dataset.psId = e);
                        })(t, e),
                        (u[e] = new r(t)),
                        u[e]
                    );
                }),
                    (n.remove = function (t) {
                        delete u[o(t)],
                            (function (t) {
                                void 0 === t.dataset
                                    ? t.removeAttribute('data-ps-id')
                                    : delete t.dataset.psId;
                            })(t);
                    }),
                    (n.get = function (t) {
                        return u[o(t)];
                    });
            },
            {
                '../lib/dom': 3,
                '../lib/event-manager': 4,
                '../lib/guid': 5,
                '../lib/helper': 6,
                './default-setting': 8,
            },
        ],
        19: [
            function (t, e, n) {
                'use strict';
                function r(t, e) {
                    return (
                        t.settings.minScrollbarLength &&
                            (e = Math.max(e, t.settings.minScrollbarLength)),
                        t.settings.maxScrollbarLength &&
                            (e = Math.min(e, t.settings.maxScrollbarLength)),
                        e
                    );
                }
                var o = t('../lib/class'),
                    i = t('../lib/dom'),
                    l = t('../lib/helper'),
                    s = t('./instances'),
                    a = t('./update-scroll');
                e.exports = function (t) {
                    var e,
                        n = s.get(t);
                    (n.containerWidth = t.clientWidth),
                        (n.containerHeight = t.clientHeight),
                        (n.contentWidth = t.scrollWidth),
                        (n.contentHeight = t.scrollHeight),
                        t.contains(n.scrollbarXRail) ||
                            ((e = i.queryChildren(t, '.ps-scrollbar-x-rail'))
                                .length > 0 &&
                                e.forEach(function (t) {
                                    i.remove(t);
                                }),
                            i.appendTo(n.scrollbarXRail, t)),
                        t.contains(n.scrollbarYRail) ||
                            ((e = i.queryChildren(t, '.ps-scrollbar-y-rail'))
                                .length > 0 &&
                                e.forEach(function (t) {
                                    i.remove(t);
                                }),
                            i.appendTo(n.scrollbarYRail, t)),
                        !n.settings.suppressScrollX &&
                        n.containerWidth + n.settings.scrollXMarginOffset <
                            n.contentWidth
                            ? ((n.scrollbarXActive = !0),
                              (n.railXWidth =
                                  n.containerWidth - n.railXMarginWidth),
                              (n.railXRatio = n.containerWidth / n.railXWidth),
                              (n.scrollbarXWidth = r(
                                  n,
                                  l.toInt(
                                      (n.railXWidth * n.containerWidth) /
                                          n.contentWidth
                                  )
                              )),
                              (n.scrollbarXLeft = l.toInt(
                                  ((n.negativeScrollAdjustment + t.scrollLeft) *
                                      (n.railXWidth - n.scrollbarXWidth)) /
                                      (n.contentWidth - n.containerWidth)
                              )))
                            : (n.scrollbarXActive = !1),
                        !n.settings.suppressScrollY &&
                        n.containerHeight + n.settings.scrollYMarginOffset <
                            n.contentHeight
                            ? ((n.scrollbarYActive = !0),
                              (n.railYHeight =
                                  n.containerHeight - n.railYMarginHeight),
                              (n.railYRatio =
                                  n.containerHeight / n.railYHeight),
                              (n.scrollbarYHeight = r(
                                  n,
                                  l.toInt(
                                      (n.railYHeight * n.containerHeight) /
                                          n.contentHeight
                                  )
                              )),
                              (n.scrollbarYTop = l.toInt(
                                  (t.scrollTop *
                                      (n.railYHeight - n.scrollbarYHeight)) /
                                      (n.contentHeight - n.containerHeight)
                              )))
                            : (n.scrollbarYActive = !1),
                        n.scrollbarXLeft >= n.railXWidth - n.scrollbarXWidth &&
                            (n.scrollbarXLeft =
                                n.railXWidth - n.scrollbarXWidth),
                        n.scrollbarYTop >= n.railYHeight - n.scrollbarYHeight &&
                            (n.scrollbarYTop =
                                n.railYHeight - n.scrollbarYHeight),
                        (function (t, e) {
                            var n = { width: e.railXWidth };
                            e.isRtl
                                ? (n.left =
                                      e.negativeScrollAdjustment +
                                      t.scrollLeft +
                                      e.containerWidth -
                                      e.contentWidth)
                                : (n.left = t.scrollLeft),
                                e.isScrollbarXUsingBottom
                                    ? (n.bottom =
                                          e.scrollbarXBottom - t.scrollTop)
                                    : (n.top = e.scrollbarXTop + t.scrollTop),
                                i.css(e.scrollbarXRail, n);
                            var r = { top: t.scrollTop, height: e.railYHeight };
                            e.isScrollbarYUsingRight
                                ? e.isRtl
                                    ? (r.right =
                                          e.contentWidth -
                                          (e.negativeScrollAdjustment +
                                              t.scrollLeft) -
                                          e.scrollbarYRight -
                                          e.scrollbarYOuterWidth)
                                    : (r.right =
                                          e.scrollbarYRight - t.scrollLeft)
                                : e.isRtl
                                ? (r.left =
                                      e.negativeScrollAdjustment +
                                      t.scrollLeft +
                                      2 * e.containerWidth -
                                      e.contentWidth -
                                      e.scrollbarYLeft -
                                      e.scrollbarYOuterWidth)
                                : (r.left = e.scrollbarYLeft + t.scrollLeft),
                                i.css(e.scrollbarYRail, r),
                                i.css(e.scrollbarX, {
                                    left: e.scrollbarXLeft,
                                    width:
                                        e.scrollbarXWidth - e.railBorderXWidth,
                                }),
                                i.css(e.scrollbarY, {
                                    top: e.scrollbarYTop,
                                    height:
                                        e.scrollbarYHeight - e.railBorderYWidth,
                                });
                        })(t, n),
                        n.scrollbarXActive
                            ? o.add(t, 'ps-active-x')
                            : (o.remove(t, 'ps-active-x'),
                              (n.scrollbarXWidth = 0),
                              (n.scrollbarXLeft = 0),
                              a(t, 'left', 0)),
                        n.scrollbarYActive
                            ? o.add(t, 'ps-active-y')
                            : (o.remove(t, 'ps-active-y'),
                              (n.scrollbarYHeight = 0),
                              (n.scrollbarYTop = 0),
                              a(t, 'top', 0));
                };
            },
            {
                '../lib/class': 2,
                '../lib/dom': 3,
                '../lib/helper': 6,
                './instances': 18,
                './update-scroll': 20,
            },
        ],
        20: [
            function (t, e, n) {
                'use strict';
                var r,
                    o,
                    i = t('./instances'),
                    l = document.createEvent('Event'),
                    s = document.createEvent('Event'),
                    a = document.createEvent('Event'),
                    c = document.createEvent('Event'),
                    u = document.createEvent('Event'),
                    d = document.createEvent('Event'),
                    p = document.createEvent('Event'),
                    h = document.createEvent('Event'),
                    f = document.createEvent('Event'),
                    v = document.createEvent('Event');
                l.initEvent('ps-scroll-up', !0, !0),
                    s.initEvent('ps-scroll-down', !0, !0),
                    a.initEvent('ps-scroll-left', !0, !0),
                    c.initEvent('ps-scroll-right', !0, !0),
                    u.initEvent('ps-scroll-y', !0, !0),
                    d.initEvent('ps-scroll-x', !0, !0),
                    p.initEvent('ps-x-reach-start', !0, !0),
                    h.initEvent('ps-x-reach-end', !0, !0),
                    f.initEvent('ps-y-reach-start', !0, !0),
                    v.initEvent('ps-y-reach-end', !0, !0),
                    (e.exports = function (t, e, n) {
                        if (void 0 === t)
                            throw 'You must provide an element to the update-scroll function';
                        if (void 0 === e)
                            throw 'You must provide an axis to the update-scroll function';
                        if (void 0 === n)
                            throw 'You must provide a value to the update-scroll function';
                        if ('top' === e && 0 >= n)
                            return (t.scrollTop = 0), void t.dispatchEvent(f);
                        if ('left' === e && 0 >= n)
                            return (t.scrollLeft = 0), void t.dispatchEvent(p);
                        var b = i.get(t);
                        return 'top' === e &&
                            n >= b.contentHeight - b.containerHeight
                            ? ((t.scrollTop =
                                  b.contentHeight - b.containerHeight),
                              void t.dispatchEvent(v))
                            : 'left' === e &&
                              n >= b.contentWidth - b.containerWidth
                            ? ((t.scrollLeft =
                                  b.contentWidth - b.containerWidth),
                              void t.dispatchEvent(h))
                            : (r || (r = t.scrollTop),
                              o || (o = t.scrollLeft),
                              'top' === e && r > n && t.dispatchEvent(l),
                              'top' === e && n > r && t.dispatchEvent(s),
                              'left' === e && o > n && t.dispatchEvent(a),
                              'left' === e && n > o && t.dispatchEvent(c),
                              'top' === e &&
                                  ((t.scrollTop = r = n), t.dispatchEvent(u)),
                              void (
                                  'left' === e &&
                                  ((t.scrollLeft = o = n), t.dispatchEvent(d))
                              ));
                    });
            },
            { './instances': 18 },
        ],
        21: [
            function (t, e, n) {
                'use strict';
                var r = t('../lib/dom'),
                    o = t('../lib/helper'),
                    i = t('./instances'),
                    l = t('./update-geometry'),
                    s = t('./update-scroll');
                e.exports = function (t) {
                    var e = i.get(t);
                    e &&
                        ((e.negativeScrollAdjustment = e.isNegativeScroll
                            ? t.scrollWidth - t.clientWidth
                            : 0),
                        r.css(e.scrollbarXRail, 'display', 'block'),
                        r.css(e.scrollbarYRail, 'display', 'block'),
                        (e.railXMarginWidth =
                            o.toInt(r.css(e.scrollbarXRail, 'marginLeft')) +
                            o.toInt(r.css(e.scrollbarXRail, 'marginRight'))),
                        (e.railYMarginHeight =
                            o.toInt(r.css(e.scrollbarYRail, 'marginTop')) +
                            o.toInt(r.css(e.scrollbarYRail, 'marginBottom'))),
                        r.css(e.scrollbarXRail, 'display', 'none'),
                        r.css(e.scrollbarYRail, 'display', 'none'),
                        l(t),
                        s(t, 'top', t.scrollTop),
                        s(t, 'left', t.scrollLeft),
                        r.css(e.scrollbarXRail, 'display', ''),
                        r.css(e.scrollbarYRail, 'display', ''));
                };
            },
            {
                '../lib/dom': 3,
                '../lib/helper': 6,
                './instances': 18,
                './update-geometry': 19,
                './update-scroll': 20,
            },
        ],
    },
    {},
    [1]
);
