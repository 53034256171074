var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { saveAs } from './external/file-saver.min.js';
export { saveAs };
export var stringPad = {
    pad: function (val, len) {
        val = String(val);
        len = len || 2;
        while (val.length < len)
            val = '0' + val;
        return val;
    },
    padEmpty: function (num, size) {
        var s = '   ' + num;
        return s.substr(s.length - size);
    },
    pad0: function (str, max) { return (str.length < max ? stringPad.pad0('0' + str, max) : str); },
};
export var saveVariable = function (variable, filename) {
    try {
        filename = typeof filename == 'undefined' ? 'variable.txt' : filename;
        variable = JSON.stringify(variable);
        var blob = new Blob([variable], { type: 'text/plain;charset=utf-8' });
        if (!saveAs)
            throw new Error('saveAs is unavailable!');
        saveAs(blob, filename);
    }
    catch (e) {
        console.debug('Unable to Save File!');
    }
};
export var saveCsv = function (items, name) {
    try {
        var replacer_1 = function (value) { return (value === null ? '' : value); }; // specify how you want to handle null values here
        var header_1 = Object.keys(items[0]);
        var csv = items.map(function (row) { return header_1.map(function (fieldName) { return JSON.stringify(row[fieldName], replacer_1); }).join(','); });
        csv.unshift(header_1.join(','));
        csv = csv.join('\r\n');
        var blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
        if (!saveAs)
            throw new Error('saveAs is unavailable!');
        saveAs(blob, name + ".csv");
    }
    catch (error) {
        console.debug('Unable to Save File!');
    }
};
export var parseRgba = function (str) {
    // eslint-disable-next-line no-useless-escape
    var _a = __read(str.match(/[\d\.]+/gu), 4), r = _a[0], g = _a[1], b = _a[2], a = _a[3];
    r = (parseInt(r) / 255);
    g = parseInt(g) / 255;
    b = parseInt(b) / 255;
    a = parseFloat(a);
    if (isNaN(r) || isNaN(g) || isNaN(b) || isNaN(a)) {
        console.warn('Bad RGBA! Using White Instead.');
        return [1, 1, 1, 1];
    }
    else {
        return [r, g, b, a];
    }
};
export var hex2RgbA = function (hex) {
    // eslint-disable-next-line prefer-named-capture-group
    if (/^#([A-Fa-f0-9]{3}){1,2}$/u.test(hex)) {
        var c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        var r = ((parseInt(c) >> 16) & 255) / 255;
        var g = ((parseInt(c) >> 8) & 255) / 255;
        var b = (parseInt(c) & 255) / 255;
        return [r, g, b, 1];
    }
    console.warn('Bad Hex! Using White Instead.');
    return [1, 1, 1, 1];
};
export var rgbCss = function (values) { return "rgba(" + values[0] * 255 + "," + values[1] * 255 + "," + values[2] * 255 + "," + values[3] + ")"; };
/**
 *
 * @param {string} str Input string
 * @param {number} num Maximum length of the string
 * @returns {string} Trunicated string
 */
export var truncateString = function (str, num) {
    if (typeof str == 'undefined')
        return 'Unknown';
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
        return str;
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + '...';
};
