const ZOOM_EXP = 3;
const TAU = 2 * Math.PI;
const DEG2RAD = TAU / 360;
const RAD2DEG = 360 / TAU;
const RADIUS_OF_EARTH = 6371.0;
const MILLISECONDS_PER_DAY = 1.15741e-8;
const RADIUS_OF_SUN = 695700;
const MINUTES_PER_DAY = 1440;
const PLANETARIUM_DIST = 3;
const RADIUS_OF_DRAW_SUN = 9000;
const SUN_SCALAR_DISTANCE = 250000;
const RADIUS_OF_DRAW_MOON = 4000;
const MOON_SCALAR_DISTANCE = 200000;
const cMPerSec = 299792458;
const cKmPerSec = 299792458 / 1000;
const cKmPerMs = 299792458 / 1000 / 1000;

export { ZOOM_EXP, TAU, DEG2RAD, RAD2DEG, RADIUS_OF_EARTH, MILLISECONDS_PER_DAY, RADIUS_OF_SUN, MINUTES_PER_DAY, PLANETARIUM_DIST, SUN_SCALAR_DISTANCE, RADIUS_OF_DRAW_SUN, RADIUS_OF_DRAW_MOON, MOON_SCALAR_DISTANCE, cMPerSec, cKmPerMs, cKmPerSec };
