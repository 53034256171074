var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
var keepTrackApi = {
    html: function (strings) {
        var e_1, _a;
        var placeholders = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            placeholders[_i - 1] = arguments[_i];
        }
        try {
            for (var placeholders_1 = __values(placeholders), placeholders_1_1 = placeholders_1.next(); !placeholders_1_1.done; placeholders_1_1 = placeholders_1.next()) {
                var placeholder = placeholders_1_1.value;
                if (typeof placeholder !== 'string') {
                    throw Error('Invalid input');
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (placeholders_1_1 && !placeholders_1_1.done && (_a = placeholders_1.return)) _a.call(placeholders_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return String.raw.apply(String, __spreadArray([strings], __read(placeholders)));
    },
    register: function (params) {
        // If this is a valid callback
        if (typeof keepTrackApi.callbacks[params.method] !== 'undefined') {
            // Add the callback
            keepTrackApi.callbacks[params.method].push({ name: params.cbName, cb: params.cb });
        }
        else {
            throw new Error("Invalid callback \"" + params.method + "\"!");
        }
        return;
    },
    unregister: function (params) {
        // If this is a valid callback
        if (typeof keepTrackApi.callbacks[params.method] !== 'undefined') {
            for (var i = 0; i < keepTrackApi.callbacks[params.method].length; i++) {
                if (keepTrackApi.callbacks[params.method][i].name == params.cbName) {
                    keepTrackApi.callbacks[params.method].splice(i, 1);
                    return;
                }
            }
            // If we got this far, it means we couldn't find the callback
            throw new Error("Callback \"" + params.cbName + " not found\"!");
        }
        else {
            // Couldn't find the method
            throw new Error("Invalid callback \"" + params.method + "\"!");
        }
    },
    callbacks: {
        selectSatData: [],
        updateSelectBox: [],
        onCruncherReady: [],
        onCruncherMessage: [],
        uiManagerInit: [],
        uiManagerOnReady: [],
        bottomMenuClick: [],
        hideSideMenus: [],
        nightToggle: [],
        orbitManagerInit: [],
        adviceReady: [],
        drawManagerLoadScene: [],
        drawOptionalScenery: [],
        updateLoop: [],
        rmbMenuActions: [],
        rightBtnMenuAdd: [],
        updateDateTime: [],
        uiManagerFinal: [],
    },
    methods: {
        selectSatData: function (sat, satId) {
            keepTrackApi.callbacks.selectSatData.forEach(function (cb) { return cb.cb(sat, satId); });
        },
        updateSelectBox: function (sat) {
            keepTrackApi.callbacks.updateSelectBox.forEach(function (cb) { return cb.cb(sat); });
        },
        onCruncherReady: function () {
            keepTrackApi.callbacks.onCruncherReady.forEach(function (cb) { return cb.cb(); });
        },
        onCruncherMessage: function () {
            keepTrackApi.callbacks.onCruncherMessage.forEach(function (cb) { return cb.cb(); });
        },
        uiManagerInit: function () {
            keepTrackApi.callbacks.uiManagerInit.forEach(function (cb) { return cb.cb(); });
        },
        uiManagerOnReady: function () {
            keepTrackApi.callbacks.uiManagerOnReady.forEach(function (cb) { return cb.cb(); });
        },
        bottomMenuClick: function (iconName) {
            keepTrackApi.callbacks.bottomMenuClick.forEach(function (cb) { return cb.cb(iconName); });
        },
        hideSideMenus: function () {
            keepTrackApi.callbacks.hideSideMenus.forEach(function (cb) { return cb.cb(); });
        },
        nightToggle: function (gl, nightTexture, texture) {
            keepTrackApi.callbacks.nightToggle.forEach(function (cb) { return cb.cb(gl, nightTexture, texture); });
        },
        orbitManagerInit: function () {
            keepTrackApi.callbacks.orbitManagerInit.forEach(function (cb) { return cb.cb(); });
        },
        adviceReady: function () {
            keepTrackApi.callbacks.adviceReady.forEach(function (cb) { return cb.cb(); });
        },
        drawManagerLoadScene: function () {
            keepTrackApi.callbacks.drawManagerLoadScene.forEach(function (cb) { return cb.cb(); });
        },
        drawOptionalScenery: function () {
            keepTrackApi.callbacks.drawOptionalScenery.forEach(function (cb) { return cb.cb(); });
        },
        updateLoop: function () {
            keepTrackApi.callbacks.updateLoop.forEach(function (cb) { return cb.cb(); });
        },
        rmbMenuActions: function (menuName) {
            keepTrackApi.callbacks.rmbMenuActions.forEach(function (cb) { return cb.cb(menuName); });
        },
        rightBtnMenuAdd: function () {
            keepTrackApi.callbacks.rightBtnMenuAdd.forEach(function (cb) { return cb.cb(); });
        },
        updateDateTime: function (date) {
            keepTrackApi.callbacks.updateDateTime.forEach(function (cb) { return cb.cb(date); });
        },
        uiManagerFinal: function () {
            keepTrackApi.callbacks.uiManagerFinal.forEach(function (cb) { return cb.cb(); });
        },
    },
    programs: {
        timeManager: {},
        settingsManager: {},
        ColorScheme: {},
        drawManager: {},
        mapManager: {},
        missileManager: {},
        objectManager: {},
        orbitManager: {},
        photoManager: {},
        satSet: {},
        satellite: {},
        searchBox: {},
        sensorManager: {},
        starManager: {},
        uiManager: {},
        uiInput: {},
    },
    firebase: []
};
window.keepTrackApi = keepTrackApi;
export { keepTrackApi };
